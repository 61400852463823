<template>
  <Password />
</template>

<script>
import Password from './components/Password.vue'

export default {
  name: 'App',
  components: { Password },
}
</script>

<style>
</style>
